import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [8,[2]],
		"/(app)/admin": [9,[2]],
		"/(app)/admin/users": [10,[2]],
		"/(app)/admin/users/create": [11,[2]],
		"/auth": [26,[5]],
		"/auth/email/error": [27,[5,6]],
		"/auth/email/error/browser": [28,[5,6]],
		"/auth/email/error/link": [29,[5,6]],
		"/auth/email/sent": [30,[5,6]],
		"/auth/entering/link": [31,[5]],
		"/dev": [32],
		"/dev/angleCalc": [33],
		"/dev/bananas": [34],
		"/dev/beep": [35],
		"/dev/benchmarking": [36],
		"/dev/benchmarking/datasctructures": [37],
		"/dev/demo": [38],
		"/dev/demo/videoFilterPOA": [39],
		"/dev/ffmpeg": [40],
		"/dev/filesystem": [41],
		"/dev/jsbuild": [42],
		"/dev/mediaSource": [43],
		"/dev/plots": [44],
		"/dev/plots/[tabNumber]": [45],
		"/dev/sentry": [46],
		"/dev/slotTest": [47],
		"/dev/tfjs": [48],
		"/dev/vanillaLoad": [49],
		"/dev/webCodecs": [50],
		"/(app)/help": [12,[2]],
		"/home": [51],
		"/(app)/invites": [13,[2]],
		"/(app)/lab": [14,[2,3]],
		"/(app)/lab/[...type]": [15,[2,3]],
		"/legal/privacy": [52,[7]],
		"/legal/terms": [53,[7]],
		"/(app)/settings/app": [16,[2,4]],
		"/(app)/settings/subscriptions": [17,[2,4]],
		"/(app)/trainees": [18,[2]],
		"/(app)/trainer": [19,[2]],
		"/(app)/users/edit": [22,[2]],
		"/(app)/users/me": [23,[2]],
		"/(app)/users/[id]": [20,[2]],
		"/(app)/users/[id]/edit": [21,[2]],
		"/(app)/videos/[id]": [24,[2]],
		"/(app)/videos/[id]/edit": [25,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';